<template>
  <div class="settings">
    <v-container fluid class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <h2 class="mb-4">Profil Bilgileri</h2>
          <v-card>
            <v-form ref="profileForm" @submit.prevent="updateProfile()">
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    id="firstName"
                    v-model="user.firstName"
                    label="Ad"
                    name="firstName"
                    prepend-icon="person"
                    type="text"
                    required
                    :rules="firstNameRules"
                  />
                  <v-text-field
                    id="lastName"
                    v-model="user.lastName"
                    label="Soyad"
                    name="lastName"
                    type="text"
                    required
                    :rules="lastNameRules"
                    class="ml-4"
                  />
                </div>
                <v-text-field
                  id="email"
                  v-model="user.email"
                  label="e-Posta"
                  name="email"
                  prepend-icon="alternate_email"
                  type="text"
                  required
                  :rules="emailRules"
                  disabled
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  text
                  :disabled="profileFormDisabled"
                  >Güncelle</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>

          <h2 class="mt-8 mb-4">Parola Değiştir</h2>
          <v-card>
            <v-form ref="passwordForm" @submit.prevent="updatePassword()">
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    id="password"
                    v-model="user.password"
                    label="Parola"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    required
                    min="6"
                    :rules="passwordRules"
                  />
                  <v-text-field
                    id="passwordConfirm"
                    v-model="user.passwordConfirm"
                    label="Parola Onayı"
                    name="passwordConfirm"
                    type="password"
                    required
                    min="6"
                    :rules="passwordConfirmRules"
                    class="ml-4"
                  />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  text
                  :disabled="passwordFormDisabled"
                  >Güncelle</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  metaInfo: () => ({
    title: "Ayarlar"
  }),
  data: () => ({
    user: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: ""
    },
    profileFormDisabled: false,
    passwordFormDisabled: false
  }),
  computed: {
    firstNameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [v => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [
        v => !!v || "Lütfen parolanızı yazınız",
        v => (v && v.length >= 6) || "En az 6 karakter olmalıdır"
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || "Lütfen parolanızı onaylayınız",
        v => (v && v.length >= 6) || "En az 6 karakter olmalıdır",
        v => v === this.user.password || "Parola onaylanmadı"
      ];
    }
  },
  methods: {
    async getProfile() {
      const user = firebase.auth().currentUser;

      if (user != null) {
        this.user.id = user.uid;
        this.user.displayName = user.displayName;
        this.user.email = user.email;

        try {
          // Veritabanından profil bilgilerini al
          const qsProfile = await firebase
            .firestore()
            .collection("businessSupervisorInvitations")
            .where("uid", "==", this.user.id)
            .get();

          const docProfile = qsProfile.docs[0];
          this.user.firstName = docProfile.data().firstName;
          this.user.lastName = docProfile.data().lastName;
        } catch (error) {
          this.$notify({
            type: "error",
            text: `Kullanıcı bilgileri alınamadı: ${error}`
          });
        }
      }
    },
    async updateProfile() {
      const form = this.$refs.profileForm;

      if (form.validate()) {
        this.profileFormDisabled = true;
        this.$notify({
          text: "Profil güncelleniyor..."
        });

        try {
          const user = firebase.auth().currentUser;

          // Profil bilgilerini güncelle
          await user.updateProfile({
            displayName: `${this.user.firstName} ${this.user.lastName}`
          });

          // e-Posta adresini güncelle
          //TODO: e-Posta güncelleme protokolünü oluştur.
          await user.updateEmail(this.user.email);

          // Kullanıcının kurumsal kaydını güncelle
          const profileQs = await firebase
            .firestore()
            .collection("businessSupervisorInvitations")
            .where("uid", "==", user.uid)
            .get();

          profileQs.docs[0].ref.update({
            firstName: this.user.firstName,
            lastName: this.user.lastName
          });

          this.$store.commit("auth/setUser", {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.lastName,
            displayName: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.email
          });

          this.$notify({
            type: "success",
            text: "Profiliniz başarıyla güncellendi."
          });
        } catch (error) {
          this.$notify({
            type: "error",
            text: `Profil bilgilerileri güncellenemedi: ${error}`
          });
        } finally {
          this.profileFormDisabled = false;
        }
      }
    },
    async updatePassword() {
      const form = this.$refs.passwordForm;
      if (form.validate()) {
        this.passwordFormDisabled = true;
        this.$notify({
          text: "Parola güncelleniyor..."
        });

        try {
          const user = firebase.auth().currentUser;

          //TODO: Parola güncelleme protokolünü düzenle
          await user.updatePassword(this.user.password);

          this.$notify({
            type: "success",
            text: "Parolanız başarıyla güncellendi."
          });
        } catch (err) {
          let msg = "";
          if (err.code == "auth/requires-recent-login") {
            msg = `Parolanızı değiştirebilmek için yeniden giriş yapmalısınız. <a href="/signin?returnUrl=/classroom/settings" class="white--text font-weight-bold">Tekrar Giriş Yap</a>`;
          } else {
            msg = "Parolanız güncellenemedi. Daha sonra tekrar deneyiniz.";
          }
          this.$notify({
            type: "error",
            text: msg
          });
        } finally {
          this.passwordFormDisabled = false;
          form.reset();
        }
      }
    }
  },
  created() {
    this.getProfile();
  }
};
</script>
